import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { css } from 'linaria'
import DownloadDocument from '~/components/Common/DownloadDocument'
import About from '~/components/Backgroundcheck/About'
import Appeal from '~/components/Backgroundcheck/Appeal'
import Faq from '~/components/Backgroundcheck/Faq'
import Flow from '~/components/Backgroundcheck/Flow'
import Security from '~/components/Backgroundcheck/Security'
import Survey from '~/components/Backgroundcheck/Survey'
import Hero from '~/components/Backgroundcheck/Hero'
import Reason from '~/components/Backgroundcheck/Reason'
import Philosophy from '~/components/Backgroundcheck/Philosophy'
import Layout from '~/components/Common/Layout'
import { STYLE } from '~/utils/constant'
import { setScrollAnimation } from '~/utils/helper'

const indexStyle = css`
  width: 100vw;
  overflow: hidden auto;

  & > .content {
    margin-top: 64px;
    padding-right: 24px;
    padding-left: 24px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 128px;
      padding-right: 32px;
      padding-left: 32px;
    }

    &.-about,
    &.-philosophy,
    &.-security,
    &.-survey,
    &.-flow {
      position: relative;
      margin-top: 0;
      padding-top: 64px;
      padding-bottom: 64px;
      background: var(--color-background-bluegray50);

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-top: 128px;
        padding-bottom: 128px;
      }
    }

    &.-security,
    &.-survey {
      padding-bottom: 0;

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-bottom: 0;
      }
    }

    &.-faq,
    &.-reason {
      position: relative;
      margin-top: 0;
      padding-top: 64px;
      padding-bottom: 64px;

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-top: 128px;
        padding-bottom: 128px;
      }
    }

    &.-appeal {
      position: relative;
      margin-top: 0;
      padding-top: 32px;
      padding-bottom: 48px;
      overflow: hidden;
      background: var(--color-gradient-key);

      @media screen and (${STYLE.MEDIA.PC}) {
        margin-top: 0;
        padding-top: 72px;
        padding-bottom: 64px;
      }
    }
  }
`

const seo = {
  title: 'バックグラウンドチェック',
}

const BackgroundcheckPage = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    setScrollAnimation()
  }, [])

  return (
    <Layout seo={seo}>
      <main className={indexStyle}>
        <Hero />
        <section id="about" className="content -about">
          <About />
        </section>
        <section id="reason" className="content -reason">
          <Reason />
        </section>
        <section className="content -philosophy">
          <Philosophy />
        </section>
        <div className="content -appeal">
          <Appeal />
        </div>
        <section id="security" className="content -security">
          <Security />
        </section>
        <section id="survey" className="content -survey">
          <Survey />
        </section>
        <section id="flow" className="content -flow">
          <Flow />
        </section>
        <div className="content -appeal">
          <Appeal />
        </div>
        <div id="faq" className="content -faq">
          <Faq />
        </div>
        <div id="request" className="download">
          <DownloadDocument />
        </div>
      </main>
    </Layout>
  )
}

export default BackgroundcheckPage
