import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const flowBackgroundcheckStyle = css`
  & > .body {
    position: relative;
    z-index: 2;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 48px;
    counter-reset: flow 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      margin-top: 80px;
      column-gap: 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      column-gap: 32px;
    }
  }

  & > .body > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 24px 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    &::before {
      content: 'STEP 0' counter(flow);
      display: flex;
      position: absolute;
      top: -14px;
      right: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 28px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 100px;
      background: var(--color-background-key);
      color: var(--color-text-bright);
      font-family: var(--font-family-number);
      font-size: ${rem(13)};
      font-weight: 700;
      white-space: nowrap;
      counter-increment: flow 1;

      @media screen and (${STYLE.MEDIA.PC}) {
        top: -15px;
        width: 112px;
        height: 30px;
        font-size: ${rem(15)};
      }
    }
  }

  & > .body > .items > .item > .title {
    margin-top: 24px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;
  }

  & > .body > .items > .item:not(:first-child) > .title {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -28px;
      right: 0;
      left: 0;
      width: 18px;
      height: 4px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 3px;
      background: var(--color-background-key);

      @media screen and (${STYLE.MEDIA.TABLET}) {
        top: 0;
        right: auto;
        bottom: 0;
        left: -14px;
        width: 6px;
        height: 20px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &::before {
      left: -15px;
      transform: rotate(25deg);
      transform-origin: 100% 50%;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        top: -15px;
        left: -14px;
        transform: rotate(-25deg);
        transform-origin: 50% 100%;
      }
    }

    &::after {
      right: -15px;
      transform: rotate(-25deg);
      transform-origin: 0% 50%;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        right: auto;
        bottom: -15px;
        transform: rotate(25deg);
        transform-origin: 50% 0%;
      }
    }
  }

  & > .body > .items > .item > .imagewrapper {
    position: relative;
    order: -1;
    width: 100%;
    aspect-ratio: 520 / 348;
    margin-right: auto;
    margin-left: auto;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      background: var(--color-background-lightblue);
    }
  }

  & > .body > .items > .item > .imagewrapper > .image {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > .body > .items > .item > .text {
    position: relative;
    margin-top: 16px;
    font-weight: 400;
  }
`

const FlowBackgroundcheckComponent = () => {
  return (
    <div className={flowBackgroundcheckStyle}>
      <div className="body">
        <h2 className="title">レポート取得の流れ</h2>
        <ul className="items">
          <li className="item" data-show-scroll="true">
            <h3 className="title">書類アップロード</h3>
            <p className="text">採用候補者の同意を取得後、履歴書・職務経歴書をアップロード してください</p>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/backgroundcheck/flow01.png"
                placeholder="none"
                alt=""
                objectFit="contain"
              />
            </div>
          </li>
          <li className="item" data-show-scroll="true">
            <h3 className="title">調査実施</h3>
            <p className="text">納品までの目安は4～5営業日です</p>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/backgroundcheck/flow02.png"
                placeholder="none"
                alt=""
                objectFit="contain"
              />
            </div>
          </li>
          <li className="item" data-show-scroll="true">
            <h3 className="title">レポート納品</h3>
            <p className="text">ASHIATOの管理画面からレポートの閲覧が可能です</p>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/backgroundcheck/flow03.png"
                placeholder="none"
                alt=""
                objectFit="contain"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FlowBackgroundcheckComponent
