import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import IconLoupe from '~/images/icon/loupe.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const aboutBackgroundcheckStyle = css`
  & > .body {
    position: relative;
    z-index: 3;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .section > .title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    &::before {
      content: '';
      width: 41px;
      height: 39px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iMzkiIHZpZXdCb3g9IjAgMCA0MSAzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2Ljk2MjQgMjAuMTcyMVYyNi44OTMyQzM2Ljk2MjQgMzIuMDg5OSAzMi43NDk2IDM2LjMwMjcgMjcuNTUyOSAzNi4zMDI3SDEyLjA5NDFDNi44OTczNyAzNi4zMDI3IDIuNjg0NTcgMzIuMDg5OSAyLjY4NDU3IDI2Ljg5MzFWMTIuMTA2OUMyLjY4NDU3IDYuOTEwMTkgNi44OTczNyAyLjY5NzM5IDEyLjA5NDEgMi42OTczOUgxOS44MjM1SDI2LjU0NDciIHN0cm9rZT0iI0ZGRDYwMCIgc3Ryb2tlLXdpZHRoPSI0LjcwNDc3IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTExLjQyMTkgMTYuMTY1TDE5LjExMyAyNC4xMTU4TDMxLjc2ODggMTEuOTczNUwzNy42ODQ1IDYuMDU3ODYiIHN0cm9rZT0iIzFFNDBDQSIgc3Ryb2tlLXdpZHRoPSI1LjA0MDgzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
    }
  }

  & > .body > .section > .title > .text {
    font-size: ${rem(32)};
    font-weight: 700;
    line-height: 1.3;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(40)};
    }
  }

  & > .body > .section > .title > .text > .em {
    color: var(--color-text-key);
  }

  & > .body > .section > .lead {
    margin-top: 32px;
    font-size: ${rem(16)};
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 48px;
      font-size: ${rem(18)};
    }
  }

  & > .body > .section > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    padding: 24px 32px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      padding-top: 48px;
      padding-bottom: 48px;
      column-gap: 32px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 40px;
      column-gap: 40px;
    }
  }

  & > .body > .section > .content > .imagewrapper {
    padding: 16px 24px 16px 12px;
    border-radius: 8px;
    background: var(--color-background-lightblue);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: 40%;
      min-width: 40%;
      margin-top: 0;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-basis: 396px;
      min-width: 396px;
    }
  }

  & > .body > .section > .content > .imagewrapper > .image {
    aspect-ratio: 396 / 292;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-right: 0;
      margin-left: 0;
      border-radius: 8px;
    }
  }

  & > .body > .section > .content > .points {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    counter-reset: point 0;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 0;
      row-gap: 56px;
    }
  }

  & > .body > .section > .content > .points > .point {
    display: flex;
    align-items: center;
    column-gap: 16px;

    @media screen and (${STYLE.MEDIA.PC}) {
      column-gap: 24px;
    }
  }

  & > .body > .section > .content > .points > .point > .number {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-text-key);
    font-family: var(--font-family-number);

    &::before {
      content: 'POINT';
      display: block;
      font-size: ${rem(12)};

      @media screen and (${STYLE.MEDIA.PC}) {
        font-size: ${rem(16)};
      }
    }

    &::after {
      content: counter(point);
      position: absolute;
      right: 0;
      bottom: 7px;
      left: 2px;
      font-size: ${rem(28)};
      line-height: 1;
      text-align: center;
      counter-increment: point 1;

      @media screen and (${STYLE.MEDIA.PC}) {
        bottom: 8px;
        left: 3px;
        font-size: ${rem(40)};
      }
    }
  }

  & > .body > .section > .content > .points > .point > .number > .icon {
    width: 40px;
    height: auto;

    @media screen and (${STYLE.MEDIA.PC}) {
      width: 54px;
    }
  }

  & > .body > .section > .content > .points > .point > .text {
    font-size: ${rem(15)};
    font-weight: 700;
    line-height: 1.75;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(20)};
    }
  }

  & > .decoration {
    display: none;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.PC}) {
      display: block;
      position: absolute;
      z-index: 2;
      bottom: -280px;
      left: calc(50% + 400px);
      width: 320px;
      height: 320px;
    }
  }

  & > .decoration > .image {
    display: block;
    width: 100%;
    height: auto;
  }
`

const AboutBackgroundcheckComponent = () => {
  return (
    <div className={aboutBackgroundcheckStyle}>
      <div className="body">
        <section className="section">
          <h2 className="title" data-show-scroll="true">
            <span className="text">
              <span className="em">
                バックグラウンド
                <br className="_mobile-inline" />
                チェック
              </span>
              とは？
            </span>
          </h2>
          <p className="lead" data-show-scroll="true">
            採用選考時において、採用候補者の過去の経歴に虚偽や問題がないかを確認・調査する選考プロセスです。
            <br className="_mobile-hide" />
            候補者本人から提出された履歴書や職務経歴書に基づき、第三者機関の調査により確認します。
          </p>
          <div className="content">
            <div className="imagewrapper">
              <StaticImage
                className="image"
                objectFit="contain"
                src="../../images/backgroundcheck/about.png"
                loading="lazy"
                placeholder="none"
                alt="バックグラウンドチェックとは？"
              />
            </div>
            <ol className="points" data-show-scroll="true">
              <li className="point">
                <div className="number">
                  <IconLoupe className="icon icon-loupe" />
                </div>
                <p className="text">
                  コンプライアンス/ガバナンス面での
                  <br className="_mobile-hide" />
                  採用リスク・レピュテーションリスクを可視化
                </p>
              </li>
              <li className="point">
                <div className="number">
                  <IconLoupe className="icon icon-loupe" />
                </div>
                <p className="text">
                  職歴/経歴の信憑性、ネットリテラシー等の
                  <br className="_mobile-hide" />
                  問題有無の発見により労務リスクを回避
                </p>
              </li>
            </ol>
          </div>
        </section>
      </div>
      <Parallax className="decoration" speed={-10}>
        <StaticImage className="image" src="../../images/logo-symbol.png" loading="lazy" placeholder="none" alt="" />
      </Parallax>
    </div>
  )
}

export default AboutBackgroundcheckComponent
