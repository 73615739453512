import React from 'react'
import { css } from 'linaria'
import IconGuard from '~/images/icon/guard.svg'
import IconLock from '~/images/icon/lock.svg'
import IconP from '~/images/icon/p.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const SecurityBackgroundcheckStyle = css`
  & > .body {
    position: relative;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-flow: row wrap;
      justify-content: center;
      column-gap: 32px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-wrap: nowrap;
    }
  }

  & > .body > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 40px;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }

  & > .body > .items > .item > .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: ${rem(24)};
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  & > .body > .items > .item > .title .iconwrapper {
    position: relative;
    width: 64px;
    height: 64px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      background: var(--color-gradient-key);
    }
  }

  & > .body > .items > .item > .title .iconwrapper > .icon {
    position: relative;
    z-index: 2;
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  & > .body > .items > .item > .title > .text {
    flex-grow: 1;
    padding-right: 8px;
    padding-left: 8px;
  }

  & > .body > .items > .item > .text {
    margin-top: 16px;
  }
`

const SecurityBackgroundcheckComponent = () => {
  return (
    <div className={SecurityBackgroundcheckStyle}>
      <div className="body">
        <h2 className="title">セキュリティ対策</h2>

        <ul className="items">
          <li className="item" data-show-scroll="true">
            <h3 className="title">
              <span className="iconwrapper">
                <IconP className="icon" />
              </span>
              <span className="text">Pマークの取得</span>
            </h3>
            <p className="text">適切に個人情報の取り扱いができる体制であることを評価するプライバシーマークを保有。</p>
          </li>
          <li className="item" data-show-scroll="true">
            <h3 className="title">
              <span className="iconwrapper">
                <IconLock className="icon" />
              </span>
              <span className="text">
                ログイン時の
                <br />
                2段階認証
              </span>
            </h3>
            <p className="text">SMSによる二段階認証を設定可能。ログイン時のセキュリティを強化できます。</p>
          </li>
          <li className="item" data-show-scroll="true">
            <h3 className="title">
              <span className="iconwrapper">
                <IconGuard className="icon" />
              </span>
              <span className="text">IPアドレス制限</span>
            </h3>
            <p className="text">アクセス可能なIPアドレスを制限可能。第三者による不正アクセスを防止できます。</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SecurityBackgroundcheckComponent
