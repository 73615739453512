import React from 'react'
import { css } from 'linaria'
import IconArrowTop from '~/images/icon/arrow-top.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const qa = [
  {
    question: '調査をすること自体に問題はないですか？',
    answer: [
      '採用候補者の同意があれば問題ございません。職業安定法第5条の4に、求職者等の個人情報の収集について本人の同意がある場合は問題ないことが記載されています。',
    ],
  },
  {
    question: '同意書を取得できなかった場合も調査は可能ですか？',
    answer: [
      '同意書がない場合の調査は、法律遵守の観点で不可です。',
      'ASHIATOでは、必ず事前に採用候補者の同意が必要な設計にしております。',
    ],
  },
  {
    question: 'どのようにして同意取得をすれば良いですか？',
    answer: ['ASHIATOのサービス上で同意取得が可能です。Web上で完結しますので、採用候補者にも負担がかかりません。'],
  },
  {
    question: 'レポートの回収期間・料金プランについて教えてください',
    answer: [
      'レポートの納品は、ご依頼から4～5営業日が目安です。',
      '調査は1件～可能です。料金については、ご希望の調査内容によって変わりますので、お問い合わせください。',
    ],
  },
]

const faqTopStyle = css`
  @keyframes accordion {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    margin-top: 40px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }

  & > .body > .items > .item {
    overflow: hidden;
    border-radius: 8px;
    background: var(--color-background-bright);

    & + .item {
      margin-top: 16px;
    }
  }

  & > .body > .items > .item > .question {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    min-height: 5.3em;
    padding: 16px 48px 16px calc(20px + 2.5em);
    transition: var(--hover-button-transition);
    border: 0;
    border-radius: 8px;
    color: var(--color-text);
    font-weight: 700;
    list-style: none;
    text-align: left;

    &::marker {
      display: none;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 64px;
      padding-left: calc(44px + 2.5em);
      font-size: ${rem(18)};
    }

    &::before {
      content: 'Q.';
      position: absolute;
      top: 24px;
      left: 16px;
      width: 1.2em;
      color: var(--color-text-key);
      font-family: var(--font-family-number);
      font-size: ${rem(27)};
      font-weight: 700;
      line-height: 1;

      @media screen and (${STYLE.MEDIA.PC}) {
        left: 36px;
        font-size: ${rem(34)};
      }
    }
  }

  & > .body > .items > .item > .question > .icon {
    position: absolute;
    top: 0;
    right: 18px;
    bottom: 0;
    height: 1em;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotateX(-180deg);
    transition: transform 0.24s var(--transition-md) 0s;
    font-size: ${rem(20)};

    @media screen and (${STYLE.MEDIA.PC}) {
      right: 36px;
      font-size: ${rem(24)};
    }
  }

  & > .body > .items > .item > .question > .icon path {
    fill: var(--color-text);
  }

  & > .body > .items > .item[open] > .question > .icon {
    transform: rotateX(0);
  }

  & > .body > .items > .item > .answer {
    position: relative;
    max-height: 0;
    overflow: hidden;
  }

  & > .body > .items > .item[open] > .answer {
    max-height: none;
  }

  & > .body > .items > .item > .answer > .content {
    padding: 0 24px 24px calc(20px + 2.5em);
    transform: translateY(-100%);
    transition: all 0.24s var(--transition-md) 0s;
    opacity: 0;
    font-weight: 400;
    line-height: 1.68;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 48px;
      padding-left: calc(48px + 2.5em);
    }

    &::before {
      content: 'A.';
      position: absolute;
      top: -4px;
      left: 20px;
      width: 1.2em;
      color: var(--color-text-sub);
      color: var(--color-text-key);
      font-family: var(--font-family-number);
      font-size: ${rem(24)};
      font-weight: 700;

      @media screen and (${STYLE.MEDIA.PC}) {
        left: 37px;
        font-size: ${rem(34)};
        line-height: 1;
      }
    }
  }

  & > .body > .items > .item[open] > .answer > .content {
    animation: accordion 0.24s var(--transition-md) 0s both;
  }
`

const FaqTopComponent = () => {
  return (
    <div className={faqTopStyle}>
      <div className="body">
        <h2 className="title">FAQ</h2>
        <div className="items">
          {qa.map((item) => {
            const answers = item.answer.map((answer) => {
              return (
                <p className="text" key={answer}>
                  {answer}
                </p>
              )
            })
            return (
              <details
                className="item"
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
                key={item.question}
                data-show-scroll="true"
              >
                <summary className="question" itemProp="name">
                  <IconArrowTop className="icon" />
                  {item.question}
                </summary>
                <div className="answer" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div className="content" itemProp="text">
                    {answers}
                  </div>
                </div>
              </details>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FaqTopComponent
