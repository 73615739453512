import React from 'react'
import { css } from 'linaria'
import IconCheck from '~/images/icon/check.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const surveys = [
  {
    title: '職務経歴/学歴',
    description: '雇用形態・役職・在籍などに詐称や過大申告がないか調査',
  },
  {
    title: '在籍期間',
    description: '在籍事実・在籍期間などへの詐称有無、未申告の就労先の有無を調査',
  },
  {
    title: 'SNSチェック',
    description: 'SNS上で不適切な投稿がないかなどのネットリテラシーを調査',
  },
  {
    title: '破産歴',
    description: '自己破産・民事再生などの法的手続きの有無を調査',
  },
  {
    title: '犯罪/逮捕歴',
    description: '150誌を超える新聞/雑誌から不正や疑惑の記録を調査',
  },
  {
    title: '反社会勢力との関与',
    description: '独自のデータベースより、反社会的勢力、右翼団体との関連有無を調査',
  },
]

const SurveyBackgroundcheckStyle = css`
  & > .body {
    position: relative;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .lead {
    margin-top: 24px;
    font-size: ${rem(16)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 32px;
      font-size: ${rem(18)};
    }
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    column-gap: 24px;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-flow: row wrap;
      margin-top: 64px;
      column-gap: 32px;
      row-gap: 32px;
    }
  }

  & > .body > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-width: calc(100% / 2 - 32px / 2);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      min-width: calc(100% / 3 - 64px / 3);
    }
  }

  & > .body > .items > .item > .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    font-size: ${rem(24)};
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  & > .body > .items > .item > .title .iconwrapper {
    display: block;
    position: relative;
    width: 24px;
    aspect-ratio: 1 / 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      background: var(--color-gradient-key);
    }
  }

  & > .body > .items > .item > .title .iconwrapper > .icon {
    position: absolute;
    z-index: 2;
    inset: 0;
    width: 80%;
    height: auto;
    margin: auto;
  }

  & > .body > .items > .item > .title > .text {
    flex-grow: 1;
    padding-right: 8px;
    padding-left: 8px;
  }

  & > .body > .items > .item > .text {
    margin-top: 16px;
  }

  & > .body > .note {
    margin-top: 16px;
    text-align: right;
  }
`

const SurveyBackgroundcheckComponent = () => {
  return (
    <div className={SurveyBackgroundcheckStyle}>
      <div className="body">
        <h2 className="title">調査内容</h2>
        <p className="lead">詳細な調査プランはお問い合わせください</p>

        <ul className="items">
          {surveys.map((item) => {
            return (
              <li className="item" key={item.title} data-show-scroll="true">
                <h3 className="title">
                  <span className="iconwrapper">
                    <IconCheck className="icon" />
                  </span>
                  {item.title}
                </h3>
                <p className="text">{item.description}</p>
              </li>
            )
          })}
        </ul>
        <p className="note">※一部抜粋</p>
      </div>
    </div>
  )
}

export default SurveyBackgroundcheckComponent
